<template>
  <sign-page
    title-text="考题"
    :request="request"
    :column-list="columnList"
    :form-parms-add="formParmsAdd"
    :dealSearchParm="dealSearchParm"
    :form-parms-update="formParmsUpdate"
    :dealFormData="dealFormData"
    :need-data-file="true">
  </sign-page>
</template>

<script>
import {
  appraisalQuestionRequest as request
} from '../../../api'

export default {
  props: {
    appraisalId: {
      type: [String, Number]
    }
  },
  computed: {
    formParmsAdd: {
      get () {
        let data = [{
          type: 'input',
          label: '序号',
          key: 'orderNum',
          check: {
            required: true,
            rules: {
              type: 'integer'
            }
          }
        },
        {
          type: 'input',
          label: '题目',
          key: 'title',
          check: {
            required: true
          }
        }]
        return data
      }
    },
    formParmsUpdate: {
      get () {
        let data = []
        this.formParmsAdd.forEach((item) => {
          let newItem = {}
          for (let key in item) {
            newItem[key] = item[key]
          }
          data.push(newItem)
        })
        return data
      }
    },
    columnList: {
      get () {
        let data = [{
          title: '序号',
          field: 'orderNum'
        },
        {
          title: '题目',
          field: 'title'
        }]
        return data
      }
    }
  },
  methods: {
    dealFormData (parm) {
      parm.appraisalId = this.appraisalId
      return parm
    },
    dealSearchParm (parm) {
      parm = parm || {}
      parm.appraisalId = this.appraisalId
      return parm
    },
    async loadData () {
      let datas = await request.get({
        appraisalId: this.appraisalId
      })
      datas.sort((a, b) => {
        return a.orderNum - b.orderNum
      })
      return datas
    }
  },
  data () {
    return {
      request: Object.assign({}, request, {
        get: this.loadData
      })
    }
  }
}
</script>
