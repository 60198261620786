<template>
  <div class="a-detail" v-loadingx="loading">
    <div class="top-c">
      <div class="info-1">
        考核管理 / 设置
      </div>
      <div class="info-2">
        考核准备: {{appraisalData ? appraisalData.title : ''}} {{appraisalData ? appraisalData.statusText : ''}} {{appraisalData ? appraisalData.typeText : ''}}
      </div>
      <div class="info-1">{{appraisalData ? appraisalData.remark : ''}}</div>
      <div class="info-1" style="color: #333145;margin-top: 16px;">{{groupInfo}}</div>
      <div class="info-1" style="display:flex;color: #333145;margin-top: 16px;width: 100%;" v-if="appraisalData && appraisalData.type === 'question'">
        <span>评分类型：</span>
        <fm-down-select style="margin-right: 35px;" @change="appraisalRuleTypeChange" :options="['选择制', '分数制'].map(v => {return {label: v, value: v}})">
          <template>
            <div class="kh-status">
              <span>{{appraisalRule.type || '未选择'}}</span>
              <div class="xsj"></div>
            </div>
          </template>
        </fm-down-select>
        <template v-if="appraisalRule.type === '选择制'">
          <span>选项类型：{{appraisalRule.ops}}</span>
        </template>
        <template v-if="appraisalRule.type === '分数制'">
          <span>分值区间：</span>
          <fm-input-new @change="minChange" v-model="appraisalRule.min" style="width: 3rem;" size="mini"></fm-input-new> -
          <fm-input-new @change="maxChange" v-model="appraisalRule.max" style="margin-right: 20px;width: 3rem;" size="mini"></fm-input-new>
          <span>分值精度：</span>
          <fm-down-select @change="appraisalRulePreChange" v-model="appraisalRule.pre" :options="['整数', '一位小数', '两位小数'].map(v => {return {label: v, value: v}})">
            <template>
              <div class="kh-status">
                <span>{{appraisalRule.pre || '未选择'}}</span>
                <div class="xsj"></div>
              </div>
            </template>
          </fm-down-select>
        </template>
        <!-- <fm-down-select style="margin-right: 35px;" @change="onChangeRule" :options="['甲乙丙丁', '优良中差', 'ABCD'].map(v => {return {label: v, value: v}})">
          <template>
            <div class="kh-status">
              <span>{{appraisalData.rule || '未选择'}}</span>
              <div class="xsj"></div>
            </div>
          </template>
        </fm-down-select> -->
      </div>
      <div class="info-1" style="display:block;color: #333145;margin-top: 16px;width: 100%;" v-if="appraisalData && appraisalData.type === 'scoring'">
        <div v-for="(item, index) in appraisalRule" :key="index" style="display:flex;height: 3rem;align-items: center;">
        <span>分项名称：</span>
        <fm-input-new @change="titleChange" v-model="appraisalRule[index].title" style="width: 5rem;margin-right: 20px;" size="mini"></fm-input-new>
        <span>分值区间：</span>
        <fm-input-new @change="minChange" v-model="appraisalRule[index].min" style="width: 3rem;" size="mini"></fm-input-new> -
        <fm-input-new @change="maxChange" v-model="appraisalRule[index].max" style="margin-right: 20px;width: 3rem;" size="mini"></fm-input-new>
        <span>分值精度：</span>
        <fm-down-select @change="appraisalRulePreChange" v-model="appraisalRule[index].pre" :options="['整数', '一位小数', '两位小数'].map(v => {return {label: v, value: v}})">
          <template>
            <div class="kh-status">
              <span>{{item.pre || '未选择'}}</span>
              <div class="xsj"></div>
            </div>
          </template>
        </fm-down-select>
        <fm-btn style="margin-left: 20px;" size="mini" @click="del(index)">删除</fm-btn>
        <fm-btn size="mini" @click="add">新增</fm-btn>
        </div>
      </div>
      <div class="info-1" style="display:flex;color: #333145;margin-top: 16px;width: 17rem;" v-if="appraisalData && appraisalData.type === 'vote'">
        <span>每人票数： </span>
        <fm-input-new style="margin-right: 35px;width: 3rem;" size="mini" v-model="voteNum" @change="onChangeRule"></fm-input-new>
      </div>
      <div class="btns">
        <fm-btn class="border-btn" v-if="appraisalData && appraisalData.type === 'question'" @click="showQ = true">考题</fm-btn>
        <imgs :appraisalId="appraisalId"></imgs>
        <fm-btn class="border-btn" @click="clickTitleMenu('order')">排序</fm-btn>
        <fm-btn @click="clickTitleMenu('add_group')">+新增分组</fm-btn>
      </div>
    </div>
    <div class="bottom-c" v-if="groups.length > 0">
      <div class="group-yb" style="margin-right: 1rem;">
        <div class="group-item" v-for="groupItem in groups.filter(a => a.type !== 'becheck')" :key="groupItem.id">
          <div class="base-info">
            <div class="base-info-l">
              <img :src="groupItem.imgSrc" alt="">
              {{groupItem.name + (groupItem.typeText ? '/' + groupItem.typeText : '')}}
            </div>
            <div class="base-info-r">
              <a href="javascript:;" style="margin-right: 4px;" class="norm-brn" @click="clickGroupTitleMenu('edit', groupItem)">修改</a>|
              <a href="javascript:;" style="margin-left: 4px;" class="norm-brn" @click="clickGroupTitleMenu('del', groupItem)">删除</a>
            </div>
          </div>
          <p class="a-remark" v-if="getGroupInfo(groupItem)" v-html="getGroupInfo(groupItem)"></p>
          <div class="user-list">
            <div class="info-4">
              <div>人员列表</div>
              <div class="base-info-r" style="margin-right: 16px" v-if="groupItem.type === 'becheck'">
                <a href="javascript:;" style="margin-right: 4px;" class="norm-brn" @click="clickGroupTitleMenu('add_detail', groupItem)">添加</a>|
                <a href="javascript:;" style="margin-left: 4px;" class="norm-brn" @click="clickGroupTitleMenu('del_detail', groupItem)">删除</a>
              </div>
            </div>
            <div style="margin-top: 6px;" v-if="groupItem.type === 'becheck'">
              <fm-table-new
                @on-column-config-update="(columns) => $onTableColumnUpdate(columns, 'main')"
                :columns="columns"
                :toolbox="showToolBox"
                export-file-name="人员列表"
                :data-list="groupItem.detailList"
                :selection="true"
                :stripe="false"
                border="row"
                @on-select-change="checkDataChange">
                <table-actions slot="actions" @table-action="tableAction($event,groupItem )" slot-scope="{ row }" :data="[{key: 'edit', label: '修改'}]" :row="row"></table-actions>
              </fm-table-new>
            </div>
            <div class="u-i-list" v-else>
              <div class="u-i" v-for="ditem in groupItem.detailList" :key="ditem.id">
                <span>{{ditem.userName}}</span><a href="javascript:;" @click="delOne(ditem)">X</a>
              </div>
              <a href="javascript:;" class="u-i-add" key="add">
                <span @click="clickGroupTitleMenu('add_detail', groupItem)">+添加</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="group-yb">
        <div class="group-item" v-for="groupItem in groups.filter(a => a.type === 'becheck')" :key="groupItem.id">
          <div class="base-info">
            <div class="base-info-l">
              <img :src="groupItem.imgSrc" alt="">
              {{groupItem.name + (groupItem.typeText ? '/' + groupItem.typeText : '')}}
            </div>
            <div class="base-info-r">
              <a href="javascript:;" style="margin-right: 4px;" class="norm-brn" @click="clickGroupTitleMenu('edit', groupItem)">修改</a>|
              <a href="javascript:;" style="margin-left: 4px;" class="norm-brn" @click="clickGroupTitleMenu('del', groupItem)">删除</a>
            </div>
          </div>
          <p class="a-remark" v-if="getGroupInfo(groupItem)" v-html="getGroupInfo(groupItem)"></p>
          <div class="user-list">
            <div class="info-4">
              <div>人员列表</div>
              <div class="base-info-r" style="margin-right: 16px" v-if="groupItem.type === 'becheck'">
                <a href="javascript:;" style="margin-right: 4px;" class="norm-brn" @click="clickGroupTitleMenu('add_detail', groupItem)">添加</a>|
                <a href="javascript:;" style="margin-left: 4px;" class="norm-brn" @click="clickGroupTitleMenu('del_detail', groupItem)">删除</a>
              </div>
            </div>
            <div style="margin-top: 6px;" v-if="groupItem.type === 'becheck'">
              <fm-table-new
                @on-column-config-update="(columns) => $onTableColumnUpdate(columns, 'main')"
                :columns="columns"
                :data-list="groupItem.detailList"
                :toolbox="showToolBox"
                export-file-name="人员列表"
                :selection="true"
                :stripe="false"
                border="row"
                @on-select-change="checkDataChange">
                <table-actions slot="actions" @table-action="tableAction($event,groupItem )" slot-scope="{ row }" :data="[{key: 'edit', label: '修改'}]" :row="row"></table-actions>
              </fm-table-new>
            </div>
            <div class="u-i-list" v-else>
              <div class="u-i" v-for="ditem in groupItem.detailList" :key="ditem.id">
                <span>{{ditem.userName}}</span><a href="javascript:;" @click="delOne(ditem)">X</a>
              </div>
              <a href="javascript:;" class="u-i-add" key="add">
                <span @click="clickGroupTitleMenu('add_detail', groupItem)">+添加</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-c n-g" v-else>
      暂无人员分组
    </div>
    <fm-form-dialog
      :mask-closable="false"
      form-title="分组"
      :open-dialog.sync="openDialog"
      :form-parms="formParms"
      label-alone
      label-align="left"
      :old-data="chooseData"
      form-width="800px"
      @formSubmit="formSubmit"
      @handleClose="openDialog = false">
    </fm-form-dialog>
    <fm-form-dialog
      :mask-closable="false"
      form-title="分组明细"
      :open-dialog.sync="openDialogDetail"
      :form-parms="isBecheck ? formParmsDetailBecheck : formParmsDetail"
      label-alone
      label-align="left"
      :old-data="isBecheck ? chooseDataDetail : null"
      form-width="800px"
      @formSubmit="formSubmitDetail"
      @handleClose="openDialogDetail = false">
    </fm-form-dialog>
    <fm-modal
      :value="openDialogPx"
      width="50%"
      :mask-closable="false"
      v-if="openDialogPx"
      theme="mh-withe"
      @cancel="openDialogPx = false">
      <fm-title
        title-text="排序"
        :title-menus="[{key: 'sjpx', label: '随机排序'}]"
        @clickTitleMenu="clickTitleMenu">
      </fm-title>
      <fm-table-new
        @on-column-config-update="(columns) => $onTableColumnUpdate(columns, 'px')"
        v-loadingx="loading"
        :columns="columnsPx"
        :data-list="beCheckList"
        :stripe="false">
      </fm-table-new>
    </fm-modal>
    <fm-modal
      :value="showQ"
      :mask-closable="false"
      width="80%"
      v-if="showQ"
      theme="mh-withe"
      @cancel="showQ = false">
      <div style="width: 100%;height: 80vh;">
        <questions :appraisalId="appraisalId"></questions>
      </div>
    </fm-modal>
  </div>
</template>

<script>
import {
  appraisalRequest as request,
  appraisalGroupRequest,
  appraisalGroupDetailRequest
} from '../../api'
import TableActions from '@/components/base/TableActions'
import Imgs from './cmp/Imgs'
import Questions from './cmp/Questions'

export default {
  components: {
    TableActions,
    Imgs,
    Questions
  },
  data () {
    return {
      showQ: false,
      loading: false,
      appraisalId: null,
      appraisalData: null,
      appraisalRule: {
        type: '选择制',
        ops: '优良中差',
        max: '100',
        min: '0',
        pre: '整数'
      },
      groups: [],
      chooseData: null,
      openDialog: false,
      chooseDataDetail: null,
      openDialogDetail: false,
      chooseDetailList: [],
      openDialogPx: false,
      voteNum: ''
    }
  },
  computed: {
    showToolBox () {
      return ['table-export', 'table-config'].filter(v => this.$authFunsProxy[v]).map(v => v.replace('table-', ''))
    },
    isBecheck () {
      return this.chooseData && this.chooseData.type === 'becheck' && this.chooseDataDetail && this.chooseDataDetail.id
    },
    groupInfo () {
      let str = '人员分组：' + this.groups.length + '组'
      str += '  考核人:' + this.groups.filter(v => v.type === 'check').reduce((a,b) => a + b.detailList.length, 0) + '位'
      str += '  被考核人:' + this.groups.filter(v => v.type === 'becheck').reduce((a,b) => a + b.detailList.length, 0) + '位'
      str += '  其他:' + this.groups.filter(v => !['check', 'becheck'].includes(v.type)).reduce((a,b) => a + b.detailList.length, 0) + '位'
      return str
    },
    baseColumns () {
      return [{
        field: 'userName',
        title: '执行人'
      },
      {
        field: 'represent',
        title: '代表单位'
      },
      {
        field: 'remark',
        title: '说明'
      },
      {
        title: '顺序',
        field: 'orderNumText'
      },
      {
        title: '操作',
        slot: 'actions',
        width: 50,
        fixed: true,
        export: false,
        configurable: false
      }]
    },
    columns () {
      return this.$getTableConfig('main', this.baseColumns)
    },
    baseColumnsPx () {
      return [{
        field: 'userName',
        title: '执行人'
      },
      {
        field: 'represent',
        title: '代表单位'
      },
      {
        field: 'remark',
        title: '说明'
      },
      {
        title: '顺序',
        field: 'orderNumText',
        align: 'left',
        width: 120,
        render: (h, row) => {
          return h('fm-poper-select', {
            props: {
              options: this.getOrderNums(row),
              confirm: true
            },
            on: {
              change: type => {
                type !== row.type && this.onFieldChange(row, type)
              }
            }
          }, [h('div', {
            class: {
              'ready-bahr-order-num': true
            },
            domProps: {
              innerHTML: row.orderNumText
            }
          })])
        }
      }]
    },
    columnsPx () {
      return this.$getTableConfig('px', this.baseColumnsPx)
    },
    groupTitleMenus () {
      return [{
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }]
    },
    titleMenus () {
      return [{
        key: 'order',
        label: '排序'
      },
      {
        key: 'add_group',
        label: '新增分组'
      }]
    },
    formParms: {
      get () {
        let data = [{
          type: 'input',
          label: '分组名',
          key: 'name',
          check: {
            required: true
          }
        },
        {
          type: 'textarea',
          label: '说明',
          key: 'remark'
        },
        {
          type: 'select',
          label: '类型',
          key: 'type',
          selectDatas: this.groupTypeList,
          fmDisabled: this.chooseData && this.chooseData.id && true,
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '打分权重',
          show: (formData) => {
            return formData.type === 'check'
          },
          key: 'rule'
        }]
        return data
      }
    },
    formParmsDetail: {
      get () {
        let data = [{
          type: 'multipleChoose',
          label: '执行人',
          key: 'workerId',
          selectDatas: this.$store.getters.allWorkerList,
          check: {
            required: true
          }
        }]
        return data
      }
    },
    formParmsDetailBecheck: {
      get () {
        let data = [{
          type: 'select',
          label: '执行人',
          key: 'workerId',
          selectDatas: this.$store.getters.allWorkerList,
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '代表单位',
          key: 'represent'
        },
        {
          type: 'textarea',
          label: '说明',
          key: 'remark'
        // },
        // {
        //   type: 'select',
        //   label: '顺序',
        //   key: 'orderNum',
        //   getSelectDatas: (data) => {
        //     return this.getOrderNums(data).map(v => {
        //       return {
        //         key: v.value,
        //         label: v.label
        //       }
        //     })
        //   }

        // },
        // {
        //   type: 'select',
        //   label: '状态',
        //   key: 'status',
        //   selectDatas: this.appraisalGroupDetailStatusList,
        //   check: {
        //     required: true
        //   }
        }]
        return data
      }
    },
    groupTypeList () {
      return this.$store.getters.appraisalGroupTypeList
    },
    statusList () {
      return this.$store.getters.appraisalStatusList
    },
    typeList () {
      return this.$store.getters.appraisalTypeList
    },
    appraisalGroupDetailStatusList () {
      return this.$store.getters.appraisalGroupDetailStatusList
    },
    beCheckList () {
      let datas = []
      this.groups.filter(v => v.type === 'becheck').forEach(v2 => datas = datas.concat(v2.detailList))
      return datas
    }
  },
  beforeRouteEnter (to, from, next) {
    if (to.query && to.query.id) {
      next()
    } else {
      next(from)
    }
  },
  created () {
    if (this.$authFunProxy['appraisal.manage-ready']) {
      this.appraisalId = this.$router.history.current.query.id
      this.$store.dispatch('loadAllWorkerList')
      this.loadData()
      this.loadGroup()
    }
  },
  methods: {
    del (index) {
      this.appraisalRule = this.appraisalRule.filter((v, index1) => index1 !== index)
      this.saveRule()
    },
    add () {
      this.appraisalRule.push({
        title: '',
        max: '100',
        min: '0',
        pre: '整数'
      })
      this.saveRule()
    },
    dealShowBkhr (dataList) {
      dataList.sort((a, b) => a.orderNumText - b.orderNumText)
      return dataList
    },
    appraisalRuleTypeChange (data) {
      this.appraisalRule.type = data
      this.saveRule()
    },
    appraisalRulePreChange () {
      this.saveRule()
    },
    titleChange () {
      this.saveRule()
    },
    minChange () {
      // if (data === '' || isNaN(data) || data < 0) {
      //   this.$notice.info({
      //     title: '系统提示',
      //     desc: '请输入合理数值'
      //   })
      //   this.appraisalRule.min = 0
      // }
      // if (data > this.appraisalRule.max) {
      //   this.$notice.info({
      //     title: '系统提示',
      //     desc: '最小值必须小于最大值'
      //   })
      //   this.appraisalRule.min = 0
      // }
      this.saveRule()
    }, 
    maxChange () {
      // if (data === '' || isNaN(data)) {
      //   this.$notice.info({
      //     title: '系统提示',
      //     desc: '请输入数值'
      //   })
      //   this.appraisalRule.max = 100
      // }
      // if (data < this.appraisalRule.min) {
      //   this.$notice.info({
      //     title: '系统提示',
      //     desc: '最大值必须大于最小值'
      //   })
      //   this.appraisalRule.max = 100
      // }
      this.saveRule()
    }, 
    saveRule () {
      let ruleText = JSON.stringify(this.appraisalRule)
      if (ruleText !== this.appraisalData.rule) {
        request.update(this.appraisalId, {
          rule: ruleText
        })
        this.appraisalData.rule = ruleText
      }
    },
    onChangeRule () {
      if (this.voteNum === '' || isNaN(this.voteNum) || this.voteNum < 0 || this.voteNum.includes('.')) {
        this.voteNum = this.appraisalData.rule
        this.$notice.info({
          title: '系统提示',
          desc: '请输入正整数'
        })
        return
      }
      if (this.voteNum !== this.appraisalData.rule) {
        request.update(this.appraisalId, {
          rule: this.voteNum
        })
        this.appraisalData.rule = this.voteNum
      }
    },
    async orderBecheck () {
      this.loading = true
      let i = 0
      let randomList = []
      while (i < this.beCheckList.length) {
        randomList.push({
          od: Math.random(),
          data: this.beCheckList[i]
        })
        i += 1
      }
      randomList.sort((a, b) => a.od - b.od)
      let allP = []
      i = 0
      while (i < randomList.length) {
        if (randomList[i].data.orderNum !== i + 1) {
          allP.push(appraisalGroupDetailRequest.update(randomList[i].data.id, {orderNum: i + 1}))
        }
        i += 1
      }
      await Promise.all(allP)
      this.loading = false
      this.$notice.success({
        title: '系统提示',
        desc: '随机排序完成'
      })
      this.loadDetailList(this.groups.filter(v => v.type === 'becheck'))
    },
    getOrderNums (data) {
      let datas = []
      this.groups.filter(v => v.type === 'becheck').forEach(v2 => datas = datas.concat(v2.detailList))
      let ops = datas.map((v, i) => String(i + 1))
      ops = ops.filter(v => v != data.orderNum)
      return ops.map(v => {
        return {value: Number(v), label: v}
      })
    },
    async onFieldChange (row, value) {
      this.loading = true
      let datas = []
      this.groups.filter(v => v.type === 'becheck').forEach(v2 => datas = datas.concat(v2.detailList))
      let data = datas.find(v => v.orderNum === value)
      if (data) {
        await appraisalGroupDetailRequest.update(data.id, {orderNum: row.orderNum})
      }
      await appraisalGroupDetailRequest.update(row.id, {orderNum: value})
      this.$notice.success({
        title: '系统提示',
        desc: '分组明细修改完成'
      })
      this.loading = false
      this.loadDetailList([{id: row.appraisalGroupId}, {id: data ? data.appraisalGroupId : -1}])
    },
    tableAction (parm, group) {
      this.chooseData = group
      this.chooseDataDetail = parm.data
      this.openDialogDetail = true
    },
    getGroupInfo (group) {
      if (!group) {
        return ''
      } else {
        let str = ''
        str += '' + (group.remark || '')
        return str
      }
    },
    formSubmit (data, resolve) {
      if (this.chooseData) {
        appraisalGroupRequest.update(this.chooseData.id, data).then(() => {
          this.$notice.success({
            title: '系统提示',
            desc: '分组修改完成'
          })
          this.loadGroup()
          resolve()
          this.openDialog = false
        })
      } else {
        data.appraisalId = this.appraisalId
        appraisalGroupRequest.add(data).then(() => {
          this.$notice.success({
            title: '系统提示',
            desc: '分组新增成功'
          })
          this.loadGroup()
          resolve()
          this.openDialog = false
        })
      }
    },
    async formSubmitDetail (data, resolve) {
      if (data && data.workerId && data.workerId.length > 0) {
        // 判断所选的人是不是已经有了
        let have = this.groups.find(v => v.id === this.chooseData.id).detailList.find(v => data.workerId.includes(v.workerId))
        if (have) {
          const result = await this.$dialog.confirm({title: '系统提示', content: '添加人员中部分人员在该分组已存在，是否继续添加?'})
          if (!result) {
            resolve()
            return
          }
        }
        // data.appraisalGroupId
        let i = 0
        while (i < data.workerId.length) {
          let parm = {
            workerId: data.workerId[i],
            appraisalGroupId: this.chooseData.id,
            status: 'wait'
          }
          await appraisalGroupDetailRequest.add(parm)
          i += 1
        }
        data.appraisalGroupId = this.chooseData.id
        this.loadDetailList([this.chooseData])
        resolve()
        this.openDialogDetail = false
        this.$notice.success({
          title: '系统提示',
          desc: '分组明细新增成功'
        })
        return
      }
      if (this.chooseDataDetail) {
        appraisalGroupDetailRequest.update(this.chooseDataDetail.id, data).then(() => {
          this.$notice.success({
            title: '系统提示',
            desc: '分组明细修改完成'
          })
          this.loadDetailList([this.chooseData])
          resolve()
          this.openDialogDetail = false
        })
      } else {
        data.appraisalGroupId = this.chooseData.id
        data.status = 'wait'
        appraisalGroupDetailRequest.add(data).then(() => {
          this.$notice.success({
            title: '系统提示',
            desc: '分组明细新增成功'
          })
          this.loadDetailList([this.chooseData])
          resolve()
          this.openDialogDetail = false
        })
      }
    },
    clickTitleMenu (key) {
      if (key === 'add_group') {
        this.chooseData = null
        this.openDialog = true
      } else if (key === 'order') {
        this.openDialogPx = true
      } else if (key === 'sjpx') {
        this.orderBecheck()
      }
    },
    async delOne (data) {
      await appraisalGroupDetailRequest.del(data.id)
      this.$notice.info({
        title: '系统提示',
        desc: '已删除'
      })
      this.loadDetailList(this.groups)
    },
    async dealDataDetail () {
      let i = 0
      while (i < this.chooseDetailList.length) {
        await appraisalGroupDetailRequest.del(this.chooseDetailList[i].id)
        i += 1
      }
      this.loadDetailList([this.chooseData])
    },
    async delData () {
      const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除分组 ' + this.chooseData.name + ' 吗?'})
      if (result) {
        appraisalGroupRequest.del(this.chooseData.id).then(() => {
          this.$notice.info({
            title: '系统提示',
            desc: '分组已删除'
          })
          this.loadGroup()
        })
      }
    },
    clickGroupTitleMenu (key, data) {
      this.chooseData = data
      if (key === 'edit') {
        this.openDialog = true
      } else if (key === 'del') {
        this.delData()
      } else if (key === 'add_detail') {
        this.chooseDataDetail = null
        this.openDialogDetail = true
      } else if (key === 'del_detail') {
        this.dealDataDetail()
      } else if (key === 'order') {
        this.orderBecheck()
      }
    },
    loadData () {
      this.loading = true
      let parm = {id: this.appraisalId}
      request.get(parm).then((data) => {
        data.forEach((v) => {
          v.statusText = (this.statusList.find(v1 => v1.key === v.status) || {label: ''}).label
          v.typeText = (this.typeList.find(v1 => v1.key === v.type) || {label: ''}).label
        })
        if (data.length == 0) {
          this.$notice.info({
            title: '系统提示',
            desc: '考核不存在'
          })
        } else {
          this.appraisalData = data[0]
          if (this.appraisalData.type === 'question') {
            this.appraisalRule = JSON.parse(this.appraisalData.rule)
          }
          if (this.appraisalData.type === 'scoring') {
            this.appraisalRule = JSON.parse(this.appraisalData.rule)
          }
          this.voteNum = this.appraisalData.rule
        }
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    checkDataChange (data) {
      this.chooseDetailList = data
    },
    loadGroup () {
      let imgMap = {
        'host': 'static/images/check/host.png',
        'check': 'static/images/check/check.png',
        'becheck': 'static/images/check/becheck.png',
        'screen': 'static/images/check/other.png',
        'other': 'static/images/check/other.png'
      }
      appraisalGroupRequest.get({appraisalId: this.appraisalId}).then((data) => {
        data.forEach((v) => {
          v.typeText = (this.groupTypeList.find(v1 => v1.key === v.type) || {label: ''}).label
          v.detailList = []
          v.imgSrc = imgMap[v.type]
        })
        this.groups = data
        this.loadDetailList(data)
      })
    },
    loadDetailList (groups) {
      if (!groups || groups.length === 0) {
        return
      }
      this.loading = true
      let parm = {
        appraisalGroupIds: groups.map(v => v.id).join(',')
      }
      appraisalGroupDetailRequest.get(parm).then((data) => {
        let dataMap = {}
        groups.forEach((v2) => dataMap[v2.id] = [])
        data.forEach((v) => {
          v.statusText = (this.appraisalGroupDetailStatusList.find(v1 => v1.key === v.status) || {label: ''}).label
          v.orderNumText = v.orderNum === null ? '无' : v.orderNum
          dataMap[v.appraisalGroupId].push(v)
        })
        this.groups.forEach(v => v.detailList = dataMap[v.id] ? dataMap[v.id] : v.detailList)
        this.groups.forEach(v => v.detailList.sort((a, b) => a.orderNumText - b.orderNumText))
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less">
.ready-bahr-order-num {
  background: rgba(47, 91, 234, 0.1);
  color: #2f5bea;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #2f5bea;
  width: 2rem;
  height: 2rem;
}
.a-detail {
  .fm-table-border-row {
    border-bottom: 0;
  }
  .fm-table-border-row .fm-table-head-cells {
    border-top: 0;
  }
  .fm-table-head-item {
    color: rgba(0,0,0,0.85);
  }
  .fm-table-new.fm-table-norm tr {
    height: 35px;
  }
  tr {
    height: 35px !important;
  }
  .fm-table-cell {
    font-size: 12px;
  }
}
</style>

<style lang="less" scoped>
.kh-status {
  display: flex;
  align-items: center;
  span {
    margin-right: 10px;
    font-size: 14px;
    color: #707070;
  }
}
.xsj {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 5px solid #00CCC4;
}
.norm-brn {
  color: #707070;
  font-size: 14px;
}
.base-info {
  height: 24px;
  padding: 18px 16px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .base-info-l {
    display: flex;
    align-items: center;
    color: #333145;
    img {
      width: 24px;
      margin-right: 8px;
    }
  }
  .base-info-r {
    display: flex;
    align-items: center;
    color: #707070;
    font-size: 14px;
  }
}
.group-yb {
  flex: 1;
  .info-4 {
    font-size: 14px;
    color: #333145;
    margin-left: 48px;
    line-height: 21px;
    margin-top: 17px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.border-btn {
  background-color: #fff !important;
  color: #00CCC4 !important;
  border: 1px solid #00CCC4 !important;
  margin-right: 5px;
  padding: 0 22px;
}
.top-c {
  position: relative;
  background: #fff;
  margin-bottom: 1rem;
  padding: 17px 32px 21px;
  .info-1 {
    color: rgba(0,0,0,0.65);
    font-size: 14px;
    line-height: 20px;
  }
  .info-2 {
    font-size: 20px;
    color: #333145;
    line-height: 1;
    margin: 17px 0;
  }
  .btns {
    position: absolute;
    bottom: 18px;
    right: 38px;
    display: flex;
  }
}
.u-i-list {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 48px;
}
.u-i-add-b {
  width: calc(100% - 56px);
  margin: 0 28px;
  height: 32px;
  color: rgba(0,0,0,0.65);
  border: 1px dashed #D9D9D9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.u-i {
  height: 22px;
  border: 1px solid #D9D9D9;
  margin: 0 1rem 1rem 0;
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  background: #F5F5F5;
  border-radius: 2px;
  color: rgba(0,0,0,0.65);
  a {
    margin-left: 1rem;
    display: none;
    border-radius: 100%;
    font-size: 1.2rem;
  }
}
.u-i:hover {
  a {
    display: block;
    color: #00CCC4;
  }
}
.u-i-add {
  height: 22px;
  border: 1px dashed #D9D9D9;
  margin: 0 1rem 1rem 0;
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  background: #FAFAFA;
  border-radius: 2px;
  color: rgba(0,0,0,0.65);
}
.a-detail {
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.group-item {
  width: 100%;
  margin-bottom: 1rem;
  background: #fff;
}
.bottom-c {
  flex: 1;
  display: flex;
  margin: 0 16px;
}
.n-g {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: #999;
}
.a-remark {
  color: rgba(0,0,0,0.45);
  margin: 0 48px;
  line-height: 2;
}
</style>